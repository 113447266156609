<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      width="50%">
    <el-form ref="form" :model="formAdd" :rules="ruleMoney">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="车牌号" :label-width="formLabelWidth" prop="carId">
            <el-select
                v-model="formAdd.carId"
                filterable
                remote
                reserve-keyword
                placeholder="请输入车牌关键字"
                :remote-method="remoteMethod"
                :loading="loading"
                @change="pickCharger"
            >
              <el-option
                  v-for="item in carList"
                  :key="item.id"
                  :label="item.carNum"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="客户名" :label-width="formLabelWidth" prop="customerName">
            <el-input v-model="formAdd.customerName" autocomplete="off" placeholder="请输入客户名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="收款日期" :label-width="formLabelWidth" prop="exceptRemindDate">
            <el-date-picker
                v-model="formAdd.exceptRemindDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择收款日期">

            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="收款类型" :label-width="formLabelWidth" prop="payType">
            <el-radio-group v-model="formAdd.payType">
              <el-radio :label="6">其他</el-radio>
              <el-radio :label="1" disabled>租金</el-radio>
              <el-radio :label="2" disabled>押金</el-radio>
              <el-radio :label="3" disabled>首收款</el-radio>
              <el-radio :label="4" disabled>尾款</el-radio>
              <el-radio :label="5" disabled>购车全款</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="收款状态" :label-width="formLabelWidth" prop="status">
            <el-radio-group v-model="formAdd.status">
              <el-radio :label="0">未收</el-radio>
              <el-radio :label="1">已收</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="应收金额" :label-width="formLabelWidth" prop="amount">
            <el-input v-model="formAdd.amount" autocomplete="off" placeholder="请输入应收金额">
              <span slot="append">元</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="formAdd.status==1">
          <el-form-item label="实收日期" :label-width="formLabelWidth" prop="payDate">
            <el-date-picker
                v-model="formAdd.payDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择实收日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="formAdd.status==1">
          <el-form-item label="实收金额" :label-width="formLabelWidth" prop="reciveAmount">
            <el-input v-model="formAdd.reciveAmount" autocomplete="off" placeholder="请输入实收金额">
              <span slot="append">元</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" :label-width="formLabelWidth" >
            <el-input v-model="formAdd.remark" autocomplete="off" placeholder="请输入备注"
                      type="textarea"
                      rows="6"
                      maxlength="200"
                      show-word-limit
                      resize="none"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {getList as carList} from "@/utils/api/car";
export default {
  //新增收款
  name: "AddCollect",
  data(){
    return{
      dialogFormVisible:false,
      title:'新增收款信息',
      formLabelWidth:"120px",
      labelPosition:"right",
      carList:[],
      value: [],
      list: [],
      options: [],
      loading: false,
      formAdd:{
        carId:"",//车牌号码编号
        customerName:"",//客户姓名
        exceptRemindDate:"",//预计提醒时间
        payType:6,//收款类型
        status:1,//收款状态
        amount:"",//应收金额
        payDate:"",//实收日期
        reciveAmount:"",//实收金额
        expenditure_flag:1,
      },
      ruleMoney:{
        carId: [
          { required: true, message: "请选择车牌信息", trigger: 'blur' },
        ],
        customerName: [
          { required: true, message: "请输入客户信息", trigger: 'blur' },
        ],
        exceptRemindDate: [
          { required: true, message: "请输入预计提醒时间", trigger: 'blur' },
        ],
      }
    }
  },
  methods:{
    showEdit() {
      this.dialogFormVisible = true;
    },
    close(){
      this.$refs["form"].resetFields();
      this.formAdd = this.$options.data().formAdd
      this.dialogFormVisible = false
      this.$emit('close')
    },
    save(){
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.$emit('add',this.formAdd)
          this.dialogFormVisible = false
          this.$refs['form'].resetFields()
          this.form = this.$options.data().formAdd
        } else {
          return false
        }
      })
    },
    pickCharger(id){
      let obj = {};
      obj =  this.carList.find((item)=>{//这里的userRoleList就是上面遍历的数据源
        return item.id == id;//筛选出匹配数据
      });
      this.formAdd.customerName = obj.customerName;
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.getCarList(query)
        }, 200);
      } else {
        this.carList = [];
      }
    },
    getCarList(key){
      let form={
        carNum:key,
        signType:1,
        rows:100000
      }
      carList(form).then(res=>{
        this.carList=res.data.list
      })
    },
  }
}
</script>

<style lang="less" scoped>
.el-select{
  width: 80%;
  .el-input{
    width: 100%;
  }
}
.el-input{
  width: 80% !important;
}
</style>
