<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      width="50%">
    <el-form ref="form" :model="formAdd" :rules="ruleMoney">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="车牌号" :label-width="formLabelWidth" prop="carId">
            <el-select
                v-model="formAdd.carId"
                filterable
                remote
                reserve-keyword
                placeholder="请输入车牌关键字"
                :remote-method="remoteMethod"
                :loading="loading"
            >
              <el-option
                  v-for="item in carList"
                  :key="item.id"
                  :label="item.carNum"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="负责人" :label-width="formLabelWidth" prop="driverId">
            <el-select v-model="formAdd.driverId" placeholder="请选择负责人"  >
              <el-option
                  v-for="item in hList"
                  :key="item.id"
                  :label="item.realName"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="付款日期" :label-width="formLabelWidth" prop="expectDate">
            <el-date-picker
                v-model="formAdd.expectDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择付款日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="付款金额" :label-width="formLabelWidth" prop="amount">
            <el-input v-model="formAdd.amount" autocomplete="off" placeholder="请输入付款金额">
              <span slot="append">元</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="付款类型" :label-width="formLabelWidth" prop="receiveType">
            <el-radio-group v-model="formAdd.receiveType">
              <el-radio :label="1">维修费</el-radio>
              <el-radio :label="2">保养费</el-radio>
              <el-radio :label="3">年检费</el-radio>
              <el-radio :label="4">保险费</el-radio>
              <el-radio :label="5">押金</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="付款状态" :label-width="formLabelWidth" prop="status">
            <el-radio-group v-model="formAdd.status">
              <el-radio :label="1">未付</el-radio>
              <el-radio :label="2">已付</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="formAdd.status==2">
          <el-form-item label="实付金额" :label-width="formLabelWidth" prop="reciveAmount">
            <el-input v-model="formAdd.reciveAmount" autocomplete="off" placeholder="请输入应付金额">
              <span slot="append">元</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="formAdd.status==2">
          <el-form-item label="实付日期" :label-width="formLabelWidth" prop="payDate">
            <el-date-picker
                v-model="formAdd.payDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择付款日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" :label-width="formLabelWidth" >
            <el-input v-model="formAdd.remark" autocomplete="off" placeholder="请输入备注"
                      type="textarea"
                      rows="6"
                      maxlength="200"
                      show-word-limit
                      resize="none"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {getCarList as carList,getResponsible} from "@/utils/api/dict";
export default {
  //新增付款
  name: "AddPay",
  data(){
    var checkStatus = (rule, value, callback) =>{
      if(this.status==2){
        callback(new Error("请填写付款日期"));
      }else{
        callback();
      }
    }
    var checkMoney = (rule, value, callback) =>{
      if(value!=this.formAdd.amount){
        callback(new Error("实付金额与应付金额不一致"));
      }else{
        callback()
      }
    }
    return{
      dialogFormVisible:false,
      title:'新增付款信息',
      formLabelWidth:"120px",
      labelPosition:"right",
      carList:[],
      value: [],
      list: [],
      options: [],
      hList:[],
      loading: false,
      formAdd:{
        carId:"",//车牌号码编号
        driverId:"",//负责人编号
        expectDate:"",//付款时间
        payType:1,//付款类型
        status:1,//付款状态
        amount:"",//付款金额
        receiveType:"",//实付日期
        reciveAmount:'',//应付金额
        expenditure_flag:2,
      },
      ruleMoney:{
        carId: [
          { required: true, message: "请选择车牌信息", trigger: 'blur' },
        ],
        driverId:[
          { required: true, message: "请选择负责人", trigger: 'blur' },
        ],
        expectDate:[
          { required: true, message: "请选择付款时间", trigger: 'blur' },
        ],
        receiveType:[
          { required: true, message: "请选择付款类型", trigger: 'blur' },
        ],
        amount:[
          { required: true, message: "请输入付款金额", trigger: 'blur' },
        ],
        status:[
          { required: true, message: "请选择付款状态", trigger: ['blur'] },
          { validator: checkStatus, trigger: ['change']  }
        ],
        reciveAmount: [
          { validator: checkMoney, trigger: ['blur']  }
        ]
      }
    }
  },
  created() {
    this.getResponsible()
  },
  methods:{
    showEdit(row) {
      this.dialogFormVisible = true;

    },
    close(){
      this.$refs["form"].resetFields();
      this.formAdd = this.$options.data().formAdd
      this.dialogFormVisible = false
      this.$emit('close')
    },
    save(){
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.$emit('add',this.formAdd)
          this.dialogFormVisible = false
          this.$refs['form'].resetFields()
          this.form = this.$options.data().formAdd
        } else {
          return false
        }
      })
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.getCarList(query)
        }, 200);
      } else {
        this.carList = [];
      }
    },
    getCarList(key){
      let form={
        carNum:key,
      }
      carList(form).then(res=>{
        this.carList=res.data
      })
    },
    //获取负责人
    getResponsible(){
      getResponsible().then(res => {
        if(res.code === 0){
          this.hList = res.data
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.el-select{
  width: 80%;
  .el-input{
    width: 100%;
  }
}
.el-input{
  width: 80% !important;
}
</style>
