<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      width="40%">
    <el-form ref="form" :model="formAdd" :rules="ruleMoney" class="info">
      <el-form-item label="本期应付" :label-width="formLabelWidth" prop="amount">
        <el-input v-model="formAdd.amount" autocomplete="off" placeholder="本期应付金额" maxlength="11" disabled>
          <span slot="append">元</span>
        </el-input>
      </el-form-item>
      <el-form-item label="实付金额" :label-width="formLabelWidth" prop="reciveAmount">
        <el-input v-model="formAdd.reciveAmount" autocomplete="off" placeholder="实付金额" type="number">
          <span slot="append">元</span>
        </el-input>
      </el-form-item>
      <el-form-item label="付款状态" :label-width="formLabelWidth" prop="status">
        <el-radio-group v-model="formAdd.status">
          <el-radio-button :label="2">已付</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="付款日期" :label-width="formLabelWidth" prop="payDate">
        <el-date-picker
            v-model="formAdd.payDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择付款日期"
            :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  //付款
  name: "Refund",
  data(){
    var checkMoney = (rule, value, callback) =>{
      if(value!=this.formAdd.amount){
        callback(new Error("实付金额与应付金额不一致"));
      }else{
        callback()
      }
    }
    return{
      dialogFormVisible: false,
      title: '新增',
      formLabelWidth: "80px",
      labelPosition: "right",
      formAdd:{
        id:"",//付款编号
        amount:"",//应付
        reciveAmount:"",//实付金额，
        status:2,//付款状态
        payDate:'',//付款日期
      },
      ruleMoney:{
        payDate:[
          { required: true, message: "请选择付款日期", trigger: 'blur' },
        ],
        status:[
          { required: true, message: "请选择付款状态", trigger: 'blur' },
        ],
        reciveAmount:[
          { required: true, message: "请填写实付金额", trigger: 'blur' },
          { validator: checkMoney, trigger: ['blur','change']  }
        ]
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }]
      },
    }
  },
  methods:{
    showEdit(row) {
      this.title = "本期付款";
      this.formAdd = Object.assign({},this.formAdd, row);
      this.dialogFormVisible = true;
    },
    close(){
      this.$refs["form"].resetFields();
      this.formAdd = this.$options.data().formAdd
      this.dialogFormVisible = false
      this.$emit('close')
    },
    save(){

      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.$emit('save',this.formAdd)
          this.$refs['form'].resetFields()
          this.dialogFormVisible = false
        } else {
          return false
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
