<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      width="40%">
    <el-form ref="form" :model="formAdd" :rules="ruleMoney" class="info">
      <el-form-item label="本期应收" :label-width="formLabelWidth" prop="amount">
        <el-input v-model="formAdd.amount" autocomplete="off" placeholder="本期应收金额" maxlength="11" disabled>
          <span slot="append">元</span>
        </el-input>
      </el-form-item>

      <el-form-item label="收款状态" :label-width="formLabelWidth" prop="status">
        <el-radio-group v-model="formAdd.status" @change="changeMoney">
          <el-radio-button :label="2">已收</el-radio-button>
          <el-radio-button :label="3">未结清</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="实收金额" :label-width="formLabelWidth" prop="reciveAmount">
        <el-input v-model="formAdd.reciveAmount" autocomplete="off" placeholder="实收金额" type="number">
          <span slot="append">元</span>
        </el-input>
      </el-form-item>
      <el-form-item label="收款日期" :label-width="formLabelWidth" prop="payDate">
        <el-date-picker
            v-model="formAdd.payDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择收款日期"
            :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  //收款
  name: "ReCollect",
  data(){
    var checkMoney = (rule, value, callback) =>{
      if(value>this.formAdd.amount){
        callback(new Error("实付金额大于应付金额"));
      }else{
        if(this.formAdd.status==2 && value<this.formAdd.amount){
          callback(new Error("实付金额无法结算全部应付金额"));
        }else if(this.formAdd.status==3 && (value>this.formAdd.amount || value==this.formAdd.amount)){
          callback(new Error("实付金额已满足应付金额"));
        }else{
          callback()
        }
      }
    }
    return{
      dialogFormVisible: false,
      title: '新增',
      formLabelWidth: "80px",
      labelPosition: "right",
      formAdd:{
        id:"",//收款编号
        amount:"",//应收
        reciveAmount:"",//实收金额，
        status:2,//收款状态
        payDate:'',//收款日期
      },
      ruleMoney:{
        payDate:[
          { required: true, message: "请选择收款日期", trigger: 'blur' },
        ],
        status:[
          { required: true, message: "请选择收款状态", trigger: 'blur' },
        ],
        reciveAmount:[
          { required: true, message: "请填写实收金额", trigger: 'blur' },
          { validator: checkMoney, trigger: ['blur']  }
        ]
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }]
      },
    }
  },
  methods:{
    showEdit(row) {
      this.title = "本期收款";
      this.formAdd = Object.assign({},this.formAdd, row);
      this.dialogFormVisible = true;
    },
    close(){
      this.$refs["form"].resetFields();
      this.formAdd = this.$options.data().formAdd
      this.dialogFormVisible = false
      this.$emit('close')
    },
    save(){
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.$emit('save',this.formAdd)
          this.$refs['form'].resetFields()
          this.dialogFormVisible = false
        } else {
          return false
        }
      })
    },
    changeMoney(e){
      if (e==2){
        this.formAdd.reciveAmount=this.formAdd.amount
      }else{
        this.formAdd.reciveAmount=''
      }
    }
  }
}
</script>

<style lang="less">

</style>
