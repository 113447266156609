<template>
  <div class="index-container">
    <div class="bread">财务管理 / <span class="gray">{{pageTitle}}</span></div>
    <ul class="payment clearfix">
      <li class="money fl" :class="{'active':isActive==='1'}" @click="changeActive('1','未收列表')">
        <div class="number">{{money1}}<span>元</span></div>
        <div class="name">未收金额</div>
      </li>
      <li class="money fl" :class="{'active':isActive==='2'}" @click="changeActive('2','实收列表')">
        <div class="number">{{money2}}<span>元</span></div>
        <div class="name">实收金额</div>
      </li>
      <li class="money fl" :class="{'active':isActive==='3'}" @click="changeActive('3','未付列表')">
        <div class="number">{{money3}}<span>元</span></div>
        <div class="name">未付金额</div>
      </li>
      <li class="money fl" :class="{'active':isActive==='4'}" @click="changeActive('4','实付管理')">
        <div class="number">{{money4}}<span>元</span></div>
        <div class="name">实付金额</div>
      </li>
    </ul>
    <div class="handle clearfix">
      <div class="fl">
        <div class="add clearfix">
          <h4 class="title fl margin-right-12">快捷操作</h4>
          <el-button type="primary" size="medium" class="fl margin-right-12" @click="addCollect">新增收款</el-button>
          <el-button type="primary" size="medium" class="fl margin-right-12" plain @click="addPay">新增付款</el-button>
        </div>
        <div class="select clearfix">
          <h4 class="title fl margin-right-12">筛选统计</h4>
          <el-select v-model="search.timeType" placeholder="请选择时间范围" size="medium" class="margin-right-12">
            <el-option label="全部" :value="null"></el-option>
            <el-option label="今日" :value="1"></el-option>
            <el-option label="本周" :value="2"></el-option>
            <el-option label="本月" :value="3"></el-option>
            <el-option label="本年" :value="4"></el-option>
          </el-select>
          <el-select v-model="search.type" placeholder="请选择收款项" size="medium" class="margin-right-12" v-if="isActive==1 || isActive==2">
            <el-option label="全部" :value="null"></el-option>
            <el-option label="押金" :value="1"></el-option>
            <el-option label="租金" :value="2"></el-option>
            <el-option label="收付款" :value="3"></el-option>
            <el-option label="尾款" :value="4"></el-option>
            <el-option label="购车全款" :value="5"></el-option>
            <el-option label="其他" :value="6"></el-option>
          </el-select>
          <el-select v-model="search.receiveType" placeholder="请选择付款项" size="medium" class="margin-right-12" v-if="isActive==3 || isActive==3">
            <el-option label="全部" :value="null"></el-option>
            <el-option label="维修费" :value="1"></el-option>
            <el-option label="保养费" :value="2"></el-option>
            <el-option label="年检费" :value="3"></el-option>
            <el-option label="保养费" :value="4"></el-option>
            <el-option label="押金" :value="5"></el-option>
          </el-select>
        </div>
      </div>
      <div class="search fr">
          <el-input size="small" type="text" placeholder="请输入车牌号" v-model="search.carNum">
          </el-input>
          <el-button type="primary" size="small" @click="toSearch">查询</el-button>

      </div>
    </div>
    <div class="list">
      <div class="change">
        <el-tabs v-model="isActive" @tab-click="handleClick(isActive)">
          <el-tab-pane label="未收款" name="1" ></el-tab-pane>
          <el-tab-pane label="实收款" name="2"></el-tab-pane>
          <el-tab-pane label="未付款" name="3"></el-tab-pane>
          <el-tab-pane label="实付款" name="4"></el-tab-pane>
        </el-tabs>
        <el-button type="primary" size="medium" class="export">导出Excel</el-button>
      </div>

      <el-table :data="tableData" style="width: 100%" ref="table">
<!--        <el-table-column-->
<!--            prop="contractNum"-->
<!--            label="签约编号"-->
<!--            min-width="200">-->
<!--        </el-table-column>-->
        <el-table-column
            fixed
            type="index"
            label="序号"
            width="80">
        </el-table-column>
        <el-table-column
            :key="Math.random()"
            v-if="isActive==1 || isActive==2"
            prop="customerName"
            label="客户姓名"
            min-width="100">
        </el-table-column>
        <el-table-column
            :key="Math.random()"
            v-if="isActive==3 || isActive==4"
            prop="chargeName"
            label="负责人姓名"
            min-width="100">
        </el-table-column>
        <el-table-column
            :key="Math.random()"
            prop="carNum"
            label="车牌"
            min-width="120">
        </el-table-column>
        <el-table-column
            :key="Math.random()"
            v-if="isActive==1 || isActive==2"
            prop="exceptRemindDate"
            label="收款日期"
            min-width="120">
        </el-table-column>
        <el-table-column
            :key="Math.random()"
            v-if="isActive==3 || isActive==4"
            prop="expectDate"
            label="付款日期"
            min-width="120">
        </el-table-column>
        <el-table-column
            v-if="isActive==1"
            :key="Math.random()"
            prop="amount"
            label="收款金额（元）"
            min-width="150">
        </el-table-column>
        <el-table-column
            v-if=" isActive==2"
            :key="Math.random()"
            prop="reciveAmount"
            label="实收金额（元）"
            min-width="150">
        </el-table-column>

        <el-table-column
            :key="Math.random()"
            v-if="isActive==3 || isActive==4"
            prop="amount"
            label="应付金额（元）"
            min-width="150">
        </el-table-column>
        <el-table-column
            :key="Math.random()"
            v-if="isActive==4"
            prop="payDate"
            label="实付日期"
            min-width="120">
        </el-table-column>
        <el-table-column
            :key="Math.random()"
            v-if=" isActive==4"
            prop="reciveAmount"
            label="实付金额（元）"
            min-width="150">
        </el-table-column>
        <el-table-column
            :key="Math.random()"
            v-if="isActive==1 || isActive==2"
            prop="type"
            label="收款类型"
            min-width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.type==1">租金</span>
            <span v-if="scope.row.type==2">押金</span>
            <span v-if="scope.row.type==3">首付款</span>
            <span v-if="scope.row.type==4">尾款</span>
            <span v-if="scope.row.type==5">购车全款</span>
            <span v-if="scope.row.type==6">其他</span>
          </template>
        </el-table-column>
        <el-table-column
            :key="Math.random()"
            v-if="isActive==3 || isActive==4"
            prop="receiveType"
            label="付款类型"
            min-width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.receiveType==1">维修费</span>
            <span v-if="scope.row.receiveType==2">保养费</span>
            <span v-if="scope.row.receiveType==3">年检费</span>
            <span v-if="scope.row.receiveType==4">保险费</span>
            <span v-if="scope.row.receiveType==5">押金</span>
          </template>
        </el-table-column>
        <el-table-column
            v-if="isActive==1 || isActive==2"
            :key="Math.random()"
            prop="status"
            label="状态"
            min-width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.status==0" class="red">未收</span>
            <span v-if="scope.row.status==1" class="gray">已收</span>
            <span v-if="scope.row.status==2" class="blue">未结清</span>
          </template>

        </el-table-column>
        <el-table-column
            v-if="isActive==3 || isActive==4"
            :key="Math.random()"
            prop="status"
            label="状态"
            min-width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.status==1" class="gray">未付</span>
            <span v-if="scope.row.status==2" class="blue">已付</span>
          </template>
        </el-table-column>
        <el-table-column
            :key="Math.random()"
            prop="remark"
            :show-overflow-tooltip="true"
            label="备注"
            min-width="200">
        </el-table-column>
        <el-table-column fixed="right" label="操作" min-width="200">
          <template slot-scope="scope">
            <el-button
                @click="toDetail(scope.row.id)"
                type="text"
                size="small">
              查看详情
            </el-button>
            <el-button
                @click="toReCollection(scope.row)"
                v-if="isActive==1"
                type="text"
                size="small">
              已收
            </el-button>
            <el-button
                @click="toRefund(scope.row)"
                v-if="isActive==3"
                type="text"
                size="small">
              已付
            </el-button>
            <el-button
                @click="deleteOne(scope.row)"
                class="red"
                type="text"
                size="small">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pages">
      <el-pagination
          background
          :hide-on-single-page="showPage"
          layout="prev, pager, next"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          :total="total"
      ></el-pagination>
    </div>
    <add-pay ref="pay" @add="addPayMoney"></add-pay>
    <add-collect ref="collect" @add="addCollectMoney"></add-collect>
    <refund ref="finish" @save ="isFinish"></refund>
    <re-collect ref="finish2" @save ="isFinish2"></re-collect>
  </div>
</template>

<script>
import {
  noPayList,
  noPayAdd,
  noPayEdit,
  noPayDel,
  isPayList,
  isPayAdd,
  isPayEdit,
  isPayDel,
  allMoney
} from '@/utils/api/financial';
import AddPay from "@/components/AddPay";
import AddCollect from "@/components/AddCollect";
import Refund from "@/components/Refund";
import ReCollect from "@/components/ReCollect";
export default {
  name: "index",
  components:{
    AddPay,AddCollect,Refund,ReCollect
  },
  data(){
    return{
      isActive:"1",
      pageTitle:"未收列表",
      money1:0,
      money2:0,
      money3:0,
      money4:0,
      search:{
        timeType:null,
        type:null,
        receiveType:null,
        carNum:""
      },
      total: 0, //总条数
      pageNo: 1,
      pageSize: 10,
      showPage: true,
      tableData: [
      ], //表格数据
    }
  },
  created() {
    this.getAllMoney()
    if (this.$route.query.state){
      this.isActive=this.$route.query.state
      if (this.isActive==3){
        this.PayList()
      }else{
        this.CollectList()
      }
    }else{
      this.CollectList()
    }
  },
  beforeUpdate(){
    this.$nextTick(() => { //在数据加载完，重新渲染表格
      this.$refs['table'].doLayout();
    })
  },
  methods:{
    changeActive(e,title){
      if (this.isActive == e){
        return
      }
      if (e==1 || e==2){
        this.CollectList()
      }
      if (e==3 || e==4){
        this.PayList()
      }
      this.isActive=e
      this.pageTitle=title
      this.search={
        timeType:null,
        payType:null
      }
    },
    handleClick(e) {
      this.isActive=e
      switch(e) {
        case '1':
          this.pageTitle='未收列表'
          this.CollectList()
          break;
        case '2':
          this.pageTitle='实收列表'
          this.CollectList()
          break;
        case '3':
          this.pageTitle='未付列表'
          this.PayList()
          break;
        case '4':
          this.pageTitle='实付管理'
          this.PayList()
          break;
        default:
          break
      }
    },
    addCollect(){
      this.$refs["collect"].showEdit();
    },
    addPay(){
      this.$refs["pay"].showEdit();
    },
    toRefund(row){
      this.$refs["finish"].showEdit(row);
    },
    toReCollection(row){
      this.$refs["finish2"].showEdit(row);
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      if (this.isActive==1 || this.isActive==2){

        this.CollectList()
      }
      if (this.isActive==3 || this.isActive==4){
        this.PayList()

      }
    },
    toDetail(id){
      switch(this.isActive) {
        case '1':
          this.$router.push({name:'未收款详情',params: {id:id}})
          break;
        case '2':
          this.$router.push({name:'实收款详情',params: {id:id}})
          break;
        case '3':
          this.$router.push({name:'未付款详情',params: {id:id}})
          break;
        case '4':
          this.$router.push({name:'实付款详情',params: {id:id}})
          break;
        default:
          break
      }
    },
    isFinish(e){
      isPayEdit(e).then(res=>{
        if (res.code==0){
          this.$baseMessage('款项已付','success')
          this.pageNo=1
          setTimeout(() => {
            this.CollectList()
          }, 200);
        }else{
          this.$baseMessage(res.msg,'warning')
        }
      })
    },
    isFinish2(e){
      noPayEdit(e).then(res=>{
        if (res.code==0){
          this.$baseMessage('款项已收','success')
          this.pageNo=1
          setTimeout(() => {
            this.CollectList()
          }, 200);
        }else{
          this.$baseMessage(res.msg,'warning')
        }
      })
    },
    toSearch(){
      this.pageNo=1
      if (this.isActive==1 || this.isActive==2){
        this.CollectList()
      }
      if (this.isActive==3 || this.isActive==4){
        this.PayList()
      }
    },
    getAllMoney(){
      allMoney().then(res=>{
        this.money1=res.data.preReceiveAmountCount
        this.money2=res.data.receiveAmountCount
        this.money3=res.data.prePayAmountCount
        this.money4=res.data.payAmountCount
      })
    },
    CollectList(){
      let form = {
        rows:this.pageSize,
        page: this.pageNo,
      }
      form= Object.assign(form, this.search)
      if (this.isActive==1){
        form.status=0
      }
      if (this.isActive==2){
        form.status=1
      }
      noPayList(form).then(res=>{
        if (res.code==0){
          this.tableData= res.data.records
          this.total=res.data.total*1
        }
      })
    },
    PayList(){
      let form = {
        rows:this.pageSize,
        page: this.pageNo,
      }
      form= Object.assign(form, this.search)
      if (this.isActive==3){
        form.status=1
      }
      if (this.isActive==4){
        form.status=2
      }
      isPayList(form).then(res=>{
        this.tableData= res.data.records
      })
    },
    deleteOne(row){
      this.$confirm('是否删除此条记录', '删除信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let id ={
          id:row.id
        }
        if (this.isActive==1 || this.isActive==2){
          this.deleteCollection(id)
        }
        if (this.isActive==3 || this.isActive==4){
          this.deletePay(id)
        }
      }).catch(() => {
      });
    },
    deleteCollection(e){
      noPayDel(e).then(res=>{
        if (res.code==0){
          this.$baseMessage('删除成功','success')
          if (this.isActive==1 || this.isActive==2){
            setTimeout(() => {
              this.pageNo=1
              this.CollectList()
            }, 200);
          }
        }else{
          this.$baseMessage(res.msg,'error')
        }
      })
    },
    deletePay(e){
      isPayDel(e).then(res=>{
        if (res.code==0){
          this.$baseMessage('删除成功','success')
          if (this.isActive==3 || this.isActive==4){
            setTimeout(() => {
              this.pageNo=1
              this.PayList()
            }, 200);
          }
        }else{
          this.$baseMessage(res.msg,'error')
        }
      })
    },
    addCollectMoney(e){
      noPayAdd(e).then(res=>{
        if (res.code==0){
          this.$baseMessage('新增收款成功','success')
          this.pageNo=1
          setTimeout(() => {
            this.CollectList()
          }, 200);
        }else{
          this.$baseMessage(res.msg,'error')
        }
      })
    },
    addPayMoney(e){
      isPayAdd(e).then(res=>{
        if (res.code==0){
          this.$baseMessage('新增付款成功','success')
          this.pageNo=1
          setTimeout(() => {
            this.PayList()
          }, 200);
        }else{
          this.$baseMessage(res.msg,'error')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.payment{
  width: 100%;
  background-color: #FFFFFF;
  .money{
    width: 25%;
    text-align: center;
    height: 134px;
    position: relative;
    &:hover{
      cursor: pointer;
    }
    &:after{
      content: '';
      width: 2px;
      height: 60px;
      background: #F1F1F1;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &:last-child{
      &:after{
        display: none;
      }
    }
    .number{
      height: 42px;
      font-size: 30px;
      font-weight: 500;
      color: #333333;
      line-height: 42px;
      letter-spacing: 1px;
      margin-top: 30px;
      span{
        font-size: 12px;
      }
    }
    .title{
      height: 22px;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
    }
  }
  .active{
      .number{
        color: #3C6CFC;
      }
      .name{
        color: #3C6CFC;
      }
  }
}
.handle{
  background-color: #FFFFFF;
  margin-top: 20px;
  padding: 30px;
  .add{
    height: 36px;
    line-height: 36px;
    margin-bottom: 30px;
    .title{
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
  }
  .search{
    height: 102px;
    display: flex;
    align-items: flex-end;
    align-content: center;
    justify-items: flex-end;
  }
  .select{
    height: 36px;
    line-height: 36px;
    .title{
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
    .el-select{
      width: 200px;
      .el-input__inner{
        background: #F5F5F5;
        color: #333333;
        border: none;
      }
    }
  }
}
.list{
  background-color: #FFFFFF;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 20px;
  .change{
    position: relative;
    padding-top: 36px;
    .export{
      position: absolute;
      right: 0;
      top: 25%;
    }
  }
}
</style>
